import AnchorLink from "components/AnchorLink";
import getClassNames from "utils/getClassNames";
import "./link.scss";

interface LinkContentProps {
  size?: 18 | 34;
  text: string;
}

const LinkContent = ({ size, text }: LinkContentProps) => (
  <>
    {text}
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size === 34 ? 26 : size}
      height={size === 34 ? 26 : size}
      viewBox="0 0 16.458 16.457"
      className={getClassNames("link__icon", { size: `size${size}` })}
    >
      <g
        id="Group_8119"
        data-name="Group 8119"
        transform="translate(-263.475 -375.293)"
      >
        <g
          id="Group_11"
          data-name="Group 11"
          transform="translate(162.693 -322.994) rotate(45)"
        >
          <path
            id="Path_1"
            data-name="Path 1"
            d="M-1449.893-10374h-15.58v-1h15.58Z"
            transform="translate(2031 10797)"
            fill="currentColor"
          />
          <path
            id="Path_2"
            data-name="Path 2"
            d="M-1439.112-10369.631l-5.647-5.646-5.646,5.646-.707-.707,6.353-6.354,6.354,6.354Z"
            transform="translate(-9794.743 1867.259) rotate(90)"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  </>
);

interface Props extends LinkContentProps {
  className?: string;
  url?: string;
}

const Link = ({ className, size = 18, url, text }: Props) => {
  const classNames = getClassNames(
    "link",
    { url: !!url },
    `font${size}${className ? ` ${className}` : ""}`
  );

  return url ? (
    <AnchorLink id={url} className={classNames}>
      <LinkContent size={size} text={text} />
    </AnchorLink>
  ) : (
    <span className={classNames}>
      <LinkContent size={size} text={text} />
    </span>
  );
};

export default Link;
