const scrollToElement = (
  element: HTMLElement,
  behavior: ScrollBehavior = "smooth"
) => {
  if (element) {
    const scrollTo = element.getBoundingClientRect().top + window.pageYOffset;
    window.scrollTo({
      top: scrollTo,
      behavior,
    });
  }
};

const scrollToElementById = (
  id: string,
  behavior: ScrollBehavior = "smooth"
) => {
  const element = document.getElementById(id);
  element && scrollToElement(element, behavior);
};

export { scrollToElement, scrollToElementById };
