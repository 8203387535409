import HeadText from "components/HeadText";
import "./HeaderTexts.scss";

const HeaderTexts = () => (
  <div className="header-texts">
    <HeadText size={2} space={3} color="secondary" url="process">
      Welcome to a&nbsp;place where&nbsp;design&nbsp;changes business.
    </HeadText>
    <HeadText size={4} url="studio">
      We are specialized in brand identity, mobile app &&nbsp;website UI – UX
      design, graphic design, packaging &&nbsp;illustration.
    </HeadText>
  </div>
);

export default HeaderTexts;
