import Link from "components/Link";
import { ReactNode } from "react";
import getClassNames from "utils/getClassNames";
import "./headText.scss";

interface Props {
  children: ReactNode;
  color?: string;
  size: number;
  space?: number;
  url?: string;
}

const HeadText = ({
  children,
  color = "primary",
  size,
  space = size,
  url,
}: Props) => (
  <div
    className={getClassNames(
      "head-text",
      {
        color,
      },
      `size${space}`
    )}
  >
    <div className={`font26 size${size}`}>{children}</div>
    {url && <Link className="head-text__link" text="More" url={url} />}
  </div>
);

export default HeadText;
