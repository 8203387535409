import Grid from "components/Grid";
import HeaderTexts from "components/HeaderTexts";
import Projects from "data/projects";
import { useLayoutEffect, useRef, useState } from "react";
import getClassNames from "utils/getClassNames";
import { scrollToElementById } from "utils/scrollToElement";
import {
  Footer,
  Header,
  Link,
  ProcessStep,
  Studio,
  Video,
} from "../../components";
import "./MainPage.scss";

const MainPage = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isVideoFullscreen, setIsVideoFullscreen] = useState(false);
  const [isVideoMuted, setIsVideoMuted] = useState(false);

  useLayoutEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (window.location.hash) {
      const hash = window.location.hash.replace("#", "");
      timeout = setTimeout(() => {
        window.history.replaceState({}, document.title, ".");
        scrollToElementById(hash, "auto");
      }, 500);
    }

    return () => {
      clearTimeout(timeout);
    };
  });

  useLayoutEffect(() => {
    const scrollHandle = () => {
      if (videoRef.current) {
        const videoRect = videoRef.current.getBoundingClientRect();
        const videoTop = videoRect.top;
        const videoBottom = videoRect.bottom;
        const height = videoRect.height;
        const halfHeight = height / 2;
        const quarterHeight = height / 4;

        if (
          !isVideoFullscreen &&
          videoTop - quarterHeight < 0 &&
          videoBottom > halfHeight
        ) {
          setIsVideoFullscreen(true);
        } else if (
          isVideoFullscreen &&
          (videoTop > quarterHeight || videoBottom < halfHeight)
        ) {
          setIsVideoFullscreen(false);
        }
      }
    };

    window.addEventListener("scroll", scrollHandle);

    return () => {
      window.removeEventListener("scroll", scrollHandle);
    };
  });

  const toggleVideoSound = () => {
    if (videoRef.current) {
      const mute = !isVideoMuted;
      videoRef.current.muted = !mute;
      setIsVideoMuted(mute);
    }
  };

  return (
    <div className="main-page">
      <div className="layout z-index-top">
        <Header isMain />
      </div>
      <div className="layout"><HeaderTexts /></div>
      <div className="main-page__video-wrapper">
        <Video
          ref={videoRef}
          url="neattypo_promo_home page.mp4"
          className={getClassNames("main-page__video", {
            fullscreen: isVideoFullscreen,
          })}
        />
        {videoRef.current && (
          <div
            className={getClassNames("main-page__video-sound-button", {
              active: !isVideoMuted,
            })}
            onClick={toggleVideoSound}
          />
        )}
      </div>
      <div className="layout">
        <div id="works" className="main-page__section">
          <Grid
            tiles={[
              { size: 3, title: "Latest works" },
              {
                size: 4,
                tile: Projects.kontakt,
              },
              {
                size: 3,
                tile: Projects.windykacja,
              },
            ]}
          />
          <Grid
            tiles={[
              {
                size: 4,
                tile: Projects.gorrion,
              },
              {
                size: 3,
                tile: Projects.vybbe,
              },
              {
                size: 3,
                tile: Projects.adapta,
              },
            ]}
          />
          <Grid
            tiles={[
              {
                size: 3,
                tile: Projects.vizitr,
              },
              {
                size: 3,
                tile: Projects.ttl,
              },
              {
                size: 4,
                tile: Projects.jumponce,
              },
            ]}
          />
        </div>
        <div id="process" className="main-page__section">
          <Link className="main-page__link" text="Process" size={34} />
          <ProcessStep
            text="Original, functional, and beautiful design is at the center of everything we create. We’re obsessed with the user experience, designing not for ourselves but for real people with real needs. We hold ourselves to high standards, pushing boundaries to design new things that feel intuitive."
            title="Design"
          >
            Digital Product Design
            <br />
            UX Design
            <br />
            Creative Web Design
            <br />
            Prototyping & User Testing
            <br />
            Style Guides, Iconography
            <br />& Illustration
          </ProcessStep>
          <ProcessStep
            text="Smart people don’t have all the answers, they ask the right questions. That’s why we always start with questions, oh so many questions. Only when we’re confident in the answers, only when we’ve gotten to the core of the challenge, and only when we really understand, do we go about solving for X."
            title="Strategy"
          >
            User Research & Persona Development
            <br />
            Data & Analytics
            <br />
            Product Definition
            <br />
            Internal & Competitive Audits
            <br />
            Site Maps & Wireframes
          </ProcessStep>
          <ProcessStep
            text="Brand and product should always work as one. Every moment of interaction is an opportunity to reinforce your brand values, voice, and point-of-view. We create brand identity systems from scratch, or evolve existing ones to make it happen. We look at the complete picture, defining and designing how the brand looks, feels, sounds, and behaves."
            title="Branding"
          >
            Startup Brandinge
            <br />
            Commerce Branding
            <br />
            Voice & Messaging
            <br />
            Logo Design
            <br />
            Brand Positioning
          </ProcessStep>
          <ProcessStep
            text="Build, learn, iterate, do it again. We know how to make logic and order out of complexity. It’s how we develop products that push past the leading-edge and become what’s next. This is how we’ve reengineered e-commerce platforms, changed the way organizations operate, and launched our own products with pioneering functionality."
            title="Development"
          >
            Frontend & Backend Development
            <br />
            App Development
            <br />
            Startup App Development
            <br />
            eCommerce App Development
            <br />
            JavaScript / Node / React -eCommerce
          </ProcessStep>
        </div>
        <div id="studio" className="main-page__section">
          <Link className="main-page__link" text="Studio" size={34} />
          <Studio />
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default MainPage;
