import Skills from "components/Skills";
import { ReactNode, useEffect, useRef, useState } from "react";
import getClassNames from "utils/getClassNames";
import "./ProcessStep.scss";

interface Props {
  children: ReactNode;
  text: string;
  title: string;
}

const ProcessStep = ({ children, text, title }: Props) => {
  const contentRef = useRef<HTMLDivElement>(null);

  const [height, setHeight] = useState(0);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setHeight(isActive ? contentRef.current?.clientHeight || 0 : 0);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [isActive]);

  return (
    <div className="process-step">
      <div
        className={getClassNames(
          "process-step__title",
          {
            active: isActive,
          },
          "font26 font-secondary"
        )}
        onClick={() => setIsActive(!isActive)}
      >
        {title}
      </div>
      <div className="process-step__content-wrapper" style={{ height }}>
        <div className="process-step__content" ref={contentRef}>
          <div className="process-step__text font22 font-gray1">{text}</div>
          <Skills>{children}</Skills>
        </div>
      </div>
    </div>
  );
};

export default ProcessStep;
