import Contact from "components/Contact";
import Letswork from "components/Letswork";
import { useState } from "react";
import getClassNames from "utils/getClassNames";
import "./MenuPopup.scss";

interface Props {
  isWhite?: boolean;
}

const MenuPopup = ({ isWhite }: Props) => {
  const [isActive, setIsActive] = useState(false);

  const show = () => {
    setIsActive(true);
    document.body.style.overflow = "hidden";
  };

  const hide = () => {
    setIsActive(false);
    document.body.style.overflow = "unset";
    setTimeout(() => {
      window.history.replaceState({}, document.title, ".");
    }, 0);
  };

  const toggle = () => {
    isActive ? hide() : show();
  };

  return (
    <div className="menu-popup">
      <div
        className={getClassNames("menu-popup__burger", {
          active: isActive,
          white: isWhite,
        })}
        onClick={toggle}
      >
        <span />
        <span />
        <span />
      </div>
      <div
        className={getClassNames("menu-popup__content", { active: isActive })}
      >
        <img className="menu-popup__logo" alt="logo" src="logo-white.svg" />
        <div className="menu-popup__menu">
          <div className="menu-popup__info">
            <Letswork className="menu-popup__letswork" />
            <Contact type="white" />
          </div>
          <div className="menu-popup__links">
            <a
              className="menu-popup__link font54 font-gray3"
              href="/#works"
              onClick={hide}
            >
              Works
            </a>
            <a
              className="menu-popup__link font54 font-gray3"
              href="/#process"
              onClick={hide}
            >
              Process
            </a>
            <a
              className="menu-popup__link font54 font-gray3"
              href="/#studio"
              onClick={hide}
            >
              Studio
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuPopup;
