import { forwardRef } from "react";
import getClassNames from "utils/getClassNames";
import "./video.scss";

interface Props {
  className?: string;
  url: string;
}

const Video = forwardRef<HTMLVideoElement, Props>(({ className, url }, ref) => (
  <video
    ref={ref}
    className={getClassNames("video", {}, className)}
    autoPlay
    muted
    controls={false}
    loop
    playsInline
  >
    <source src={url} />
  </video>
));

export default Video;
