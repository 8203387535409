import getClassNames from "utils/getClassNames";
import "./letswork.scss";

interface Props {
  className?: string;
}

const Letswork = ({ className }: Props) => (
  <div className={getClassNames("letswork", {}, className)}>
    <div className="letswork__text font26">
      <span className="letswork__line">Let’s work together</span>
      <span className="letswork__line font-secondary">Get in touch</span>
    </div>
  </div>
);

export default Letswork;
