import getClassNames from "utils/getClassNames";
import "./contact.scss";

interface Props {
  type?: "default" | "white";
}

const Contact = ({ type = "default" }: Props) => (
  <div
    className={getClassNames(
      "contact",
      {
        type: type === "default" ? false : type,
      },
      "font18"
    )}
  >
    <a href="tel:+48791802083" className="contact__link">
      +48 791 802 083
    </a>
    <a href="mailto:hello@neattypo.com" className="contact__link">
      hello@neattypo.com
    </a>
  </div>
);

export default Contact;
