import Skills from "components/Skills";
import Projects from "data/projects";
import CasePage from "pages/CasePage";
import MainPage from "pages/MainPage";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.scss";

const App = () => {
  return (
    <BrowserRouter>
      <div className="app">
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route
            path="/kontakt.io"
            element={
              <CasePage
                coverUrl="covers/cover_8.png"
                isWhite={true}
                projects={[Projects.gorrion, Projects.vybbe, Projects.adapta]}
                skills={
                  <Skills>
                    Digital Product Design
                    <br />
                    Brand Positioning
                    <br />
                    Motion Design
                    <br />
                    Creative Web Design
                    <br />
                    UX Design
                    <br />
                    UI Design
                    <br />
                    Prototyping & User Testing
                    <br />
                    Style Guides, Iconography <br />
                    & Illustration
                    <br />
                    Printing materials
                    <br />
                    Packaging
                  </Skills>
                }
                tiles={[
                  {
                    size: 1,
                    url: "tiles/kontakt.io/kontakt.io_18-min.png",
                  },
                  {
                    size: 2,
                    url: "tiles/kontakt.io/kontakt.io_9-min.png",
                  },
                  {
                    size: 2,
                    url: "tiles/kontakt.io/kontakt.io_8-min.png",
                  },
                  {
                    size: 1,
                    url: "tiles/kontakt.io/nano tag landing.mp4",
                    isVideo: true,
                  },
                  {
                    size: 1,
                    url: "tiles/kontakt.io/Nano Tag by Kontakt.io.mp4",
                    isVideo: true,
                  },
                  {
                    size: 2,
                    url: "tiles/kontakt.io/kontakt.io_13-min.png",
                  },
                  {
                    size: 2,
                    url: "tiles/kontakt.io/kontakt.io_10-min.png",
                  },
                  {
                    size: 1,
                    url: "tiles/kontakt.io/kontakt.io_2-min.png",
                  },
                  {
                    size: 1,
                    url: "tiles/kontakt.io/Asset Tag 2 by Kontakt.io.mp4",
                    isVideo: true,
                  },
                  {
                    size: 1,
                    url: "tiles/kontakt.io/kontakt.io_3-min.png",
                  },
                  {
                    size: 2,
                    url: "tiles/kontakt.io/kontakt.io_11-min.png",
                  },
                  {
                    size: 2,
                    url: "tiles/kontakt.io/kontakt.io_14-min.png",
                  },
                  {
                    size: 1,
                    url: "tiles/kontakt.io/kontak.io_13.png",
                  },
                  {
                    size: 2,
                    url: "tiles/kontakt.io/kontakt.io_5-min.png",
                  },
                  {
                    size: 2,
                    url: "tiles/kontakt.io/kontakt.io_12-min.png",
                    link: "https://kontakt.io/smart-badge",
                  },
                  {
                    size: 1,
                    url: "tiles/kontakt.io/herpo page_2.mp4",
                    isVideo: true,
                  },
                  {
                    size: 1,
                    url: "tiles/kontakt.io/kontakt.io_16-min.png",
                  },
                  {
                    size: 2,
                    url: "tiles/kontakt.io/kontakt.io_6-min.png",
                  },
                  {
                    size: 2,
                    url: "tiles/kontakt.io/kontakt.io_4-min.png",
                  },
                  {
                    size: 1,
                    url: "tiles/kontakt.io/kontakt.io_17-min.png",
                  },
                  {
                    size: 2,
                    url: "tiles/kontakt.io/kontakt.io_7-min.png",
                  },
                  {
                    size: 2,
                    url: "tiles/kontakt.io/kontakt.io_15-min.png",
                  },
                ]}
                text={
                  <>
                    Working for the leader of the growing IoT market. Could
                    there be anything better?
                    <br />
                    A global brand means global thinking in the context of the
                    company's image. From building communication with a prospect
                    client all the way to implementation and satisfaction, when
                    well-designed tools help people at work.
                    <br />
                    Despite the high intensity of activities and a wide range of
                    tasks, the final reward such as a well-functioning product
                    is a fulfillment for every designer :)
                  </>
                }
                title="kontakt.io"
              />
            }
          />
          <Route
            path="/gorrion.io"
            element={
              <CasePage
                coverUrl="covers/cover_gorrion.mp4"
                isVideo={true}
                isWhite={false}
                projects={[Projects.kontakt, Projects.ttl, Projects.jumponce]}
                skills={
                  <Skills>
                    Logo DesignBrand Positioning
                    <br />
                    Motion Design
                    <br />
                    Creative Web Design
                    <br />
                    UX Design
                    <br />
                    UI Design
                    <br />
                    Prototyping & User Testing
                    <br />
                    Style Guides, Iconography
                    <br />& Illustration
                  </Skills>
                }
                tiles={[
                  {
                    size: 2,
                    url: "tiles/gorrion.io/Gorrion_min_20.png",
                  },
                  {
                    size: 2,
                    url: "tiles/gorrion.io/Gorrion_min_3.png",
                  },
                  {
                    size: 2,
                    url: "tiles/gorrion.io/Gorrion_min_17.png",
                  },
                  {
                    size: 2,
                    url: "tiles/gorrion.io/Gorrion_min_18.png",
                  },
                  {
                    size: 1,
                    url: "tiles/gorrion.io/Gorrion_min_16.png",
                  },
                  {
                    size: 1,
                    url: "tiles/gorrion.io/Gorrion_min_15.png",
                  },
                  {
                    size: 2,
                    url: "tiles/gorrion.io/Gorrion_min_14.png",
                  },
                  {
                    size: 2,
                    url: "tiles/gorrion.io/Gorrion_min_13.png",
                  },
                  {
                    size: 1,
                    url: "tiles/gorrion.io/Gorrion_min_12.png",
                  },
                  {
                    size: 2,
                    url: "tiles/gorrion.io/Gorrion_min_11.png",
                  },
                  {
                    size: 2,
                    url: "tiles/gorrion.io/Gorrion_min_1.png",
                    children: [
                      {
                        size: 2,
                        url: "tiles/gorrion.io/Gorrion_min_1.png",
                      },
                      {
                        size: 2,
                        url: "tiles/gorrion.io/Gorrion_min_2.png",
                      },
                    ],
                  },
                  {
                    size: 2,
                    url: "tiles/gorrion.io/Gorrion_min_10.png",
                  },
                  {
                    size: 2,
                    url: "tiles/gorrion.io/Gorrion_min_9.png",
                  },
                  {
                    size: 1,
                    url: "tiles/gorrion.io/Gorrion_min_19.png",
                  },
                  {
                    size: 1,
                    // url: "tiles/gorrion.io/Gorrion_min_8.png",
                    url: "tiles/gorrion.io/gorrion_MVP.mp4",
                    isVideo: true,
                  },
                  {
                    size: 1,
                    url: "tiles/gorrion.io/Gorrion_min_4.png",
                  },
                  {
                    size: 2,
                    url: "tiles/gorrion.io/Gorrion_min_7.png",
                  },
                  {
                    size: 2,
                    url: "tiles/gorrion.io/Gorrion_min_6.png",
                  },
                  {
                    size: 1,
                    url: "tiles/gorrion.io/Gorrion_min_5.png",
                  },
                ]}
                text={
                  <>
                    The task seemed simple, but the amount of work was enormous.
                    Distinguish the company from the competition and give it a
                    unique character. Sounds familiar? Not only the brand needed
                    refreshing, but a complete change to how it positioned
                    itself on the market was crucial. The only thing that was
                    left untouched is the name itself, which is very powerful
                    and brings positive connotations.
                    <br />
                    The whole process of changes and implementation was pure
                    pleasure. Working with informed clients is what we like the
                    most!
                  </>
                }
                title="Gorrion"
                subtitle="software house"
              />
            }
          />
          <Route
            path="/vybbe"
            element={
              <CasePage
                coverUrl="covers/cover_3.png"
                isWhite={true}
                projects={[
                  Projects.kontakt,
                  Projects.windykacja,
                  Projects.adapta,
                ]}
                skills={
                  <Skills>
                    Logo Design
                    <br />
                    Brand Positioning
                    <br />
                    Digital Product Design
                    <br />
                    UX Design
                    <br />
                    UI Design
                    <br />
                    Frontend & Backend Development
                    <br />
                    App Development
                    <br />
                    Startup App Development
                  </Skills>
                }
                tiles={[
                  {
                    size: 2,
                    url: "tiles/vybbe/vybbe_2-min.png",
                  },
                  {
                    size: 2,
                    url: "tiles/vybbe/vybbe_1-min.png",
                  },
                  {
                    size: 1,
                    url: "tiles/vybbe/vybbe_3-min.png",
                  },
                  {
                    size: 1,
                    url: "tiles/vybbe/vybbe_4-min.png",
                  },
                  {
                    size: 1,
                    url: "tiles/vybbe/vybbe_5-min.png",
                  },
                  {
                    size: 2,
                    url: "tiles/vybbe/vybbe_6-min.png",
                  },
                  {
                    size: 2,
                    url: "tiles/vybbe/vybbe_8-min.png",
                  },
                  {
                    size: 1,
                    url: "tiles/vybbe/vybbe_7-min.png",
                  },
                ]}
                text={
                  <>
                    Throughout our lives, we are judged and subjected to social
                    pressure. How to create a good and pleasant internal tool
                    that enables employees to share their emotions and feelings
                    with each other, as well as allows the management to have an
                    insight into the current atmosphere in the company? Although
                    the task seemed difficult, a product was created that all
                    employees loved!!!
                  </>
                }
                title="vybbe"
              />
            }
          />
          <Route
            path="/windykacja"
            element={
              <CasePage
                coverUrl="covers/cover_7.png"
                isWhite={true}
                projects={[
                  Projects.kontakt,
                  Projects.jumponce,
                  Projects.adapta,
                ]}
                skills={
                  <Skills>
                    Logo Design
                    <br />
                    Brand Positioning
                    <br />
                    Motion Design
                    <br />
                    Creative Web Design
                    <br />
                    UX Design
                    <br />
                    UI Design
                    <br />
                    Style Guides, Iconography
                    <br />
                    & Illustration
                    <br />
                    Printing materials
                  </Skills>
                }
                tiles={[
                  {
                    size: 1,
                    url: "tiles/windykacja/Windykacja plus_1.mp4",
                    isVideo: true,
                  },
                  {
                    size: 2,
                    url: "tiles/windykacja/Windykacja plus_4.png",
                  },
                  {
                    size: 2,
                    url: "tiles/windykacja/Windykacja plus_5.png",
                  },
                  {
                    size: 1,
                    url: "tiles/windykacja/Windykacja plus_7.png",
                  },
                  {
                    size: 2,
                    url: "tiles/windykacja/Windykacja plus_8.png",
                  },
                  {
                    size: 2,
                    url: "tiles/windykacja/Windykacja plus_6.png",
                  },
                  {
                    size: 1,
                    url: "tiles/windykacja/Windykacja plus_2.png",
                  },
                ]}
                text={
                  <>
                    Financial problems? Difficulty getting your money back? An
                    unpleasant situation for both parties. Fortunately, there
                    are companies that are trying to change the industry and
                    meet the problems of both sides of the conflict. The created
                    brand was designed to show that you can get out of every
                    situation, and good communication in nervous situations is
                    the basis for dealing with problems.
                  </>
                }
                title="Windykacja +"
              />
            }
          />
          <Route
            path="/adapta"
            element={
              <CasePage
                coverUrl="covers/cover_5.png"
                isWhite={false}
                projects={[Projects.kontakt, Projects.vybbe, Projects.vizitr]}
                skills={
                  <Skills>
                    Logo Design
                    <br />
                    Brand Positioning
                    <br />
                    Motion Design
                    <br />
                    Creative Web Design
                    <br />
                    UX Design
                    <br />
                    UI Design
                    <br />
                    Style Guides, Iconography
                    <br />
                    & Illustration
                    <br />
                    Printing materials
                  </Skills>
                }
                tiles={[
                  {
                    size: 1,
                    url: "tiles/adapta/Adapta_1-min.png",
                    link: "https://www.pokonajlek.pl",
                  },
                  {
                    size: 2,
                    url: "tiles/adapta/Adapta_3-min.png",
                  },
                  {
                    size: 2,
                    url: "tiles/adapta/Adapta_2-min.png",
                  },
                  {
                    size: 1,
                    url: "tiles/adapta/Adapta_4-min.png",
                  },
                  {
                    size: 2,
                    url: "tiles/adapta/Adapta_5-min.png",
                  },
                  {
                    size: 2,
                    url: "tiles/adapta/Adapta_7-min.png",
                  },
                  {
                    size: 1,
                    url: "tiles/adapta/Adapta_6-min.png",
                  },
                ]}
                text={
                  <>
                    Mental health is crucial to normal functioning. How to deal
                    with crisis situations? Who to ask for advice if we are
                    alone? Where to find motivation and strength every day?
                    These and many more difficult questions are answered by
                    Adapta, a great company providing online psychotherapeutic
                    services at the highest level. It is a pleasure to work for
                    a brand whose task is not only to develop and earn money,
                    but also to help people on an ad hoc basis.
                  </>
                }
                title="Adapta"
              />
            }
          />
          <Route
            path="/vizitr"
            element={
              <CasePage
                coverUrl="covers/cover_6.png"
                isWhite={true}
                projects={[Projects.kontakt, Projects.vybbe, Projects.gorrion]}
                skills={
                  <Skills>
                    Logo Design
                    <br />
                    Brand Positioning
                    <br />
                    Digital Product Design
                    <br />
                    UX Design
                    <br />
                    UI Design
                    <br />
                    Frontend & Backend Development
                    <br />
                    App Development
                  </Skills>
                }
                tiles={[
                  {
                    size: 1,
                    url: "tiles/vizitr/Vizitr_1-min.png",
                  },
                  {
                    size: 2,
                    url: "tiles/vizitr/Vizitr_3-min.png",
                  },
                  {
                    size: 2,
                    url: "tiles/vizitr/Vizitr_2-min.png",
                  },
                  {
                    size: 1,
                    url: "tiles/vizitr/Vizitr_4-min.png",
                  },
                  {
                    size: 2,
                    url: "tiles/vizitr/Vizitr_5-min.png",
                  },
                  {
                    size: 2,
                    url: "tiles/vizitr/Vizitr_6-min.png",
                  },
                ]}
                text={
                  <>
                    Are you buying? Selling? Do you want to report damages to
                    your insurer? Our task was to make an application that
                    allows you to do all of that. We changed the brand and gave
                    it a more contemporary character.
                  </>
                }
                title="vizitr"
              />
            }
          />
          <Route
            path="/ttl"
            element={
              <CasePage
                coverUrl="covers/cover_2.png"
                isWhite={false}
                projects={[
                  Projects.jumponce,
                  Projects.gorrion,
                  Projects.kontakt,
                ]}
                skills={
                  <Skills>
                    Logo Design
                    <br />
                    Brand Positioning
                    <br />
                    Motion Design
                    <br />
                    Creative Web Design
                    <br />
                    UX Design
                    <br />
                    UI Design
                    <br />
                    Style Guides, Iconography <br />
                    & Illustration
                    <br />
                    Printing materials
                  </Skills>
                }
                tiles={[
                  {
                    size: 2,
                    url: "tiles/ttl/The Three Legends_1-min.png",
                  },
                  {
                    size: 2,
                    url: "tiles/ttl/The Three Legends_2-min.png",
                  },
                  {
                    size: 1,
                    url: "tiles/ttl/The Three Legends_3-min.png",
                    link: "https://www.thethreelegends.com",
                  },
                  {
                    size: 2,
                    url: "tiles/ttl/The Three Legends_5-min.png",
                  },
                  {
                    size: 2,
                    url: "tiles/ttl/The Three Legends_4-min.png",
                  },
                  {
                    size: 1,
                    url: "tiles/ttl/The Three Legends_6-min.png",
                  },
                  {
                    size: 2,
                    url: "tiles/ttl/The Three Legends_8-min.png",
                  },
                  {
                    size: 2,
                    url: "tiles/ttl/The Three Legends_7-min.png",
                  },
                ]}
                text={
                  <>
                    Good ideas are born spontaneously from the need of the
                    moment. This time, a group of friends wanted to create
                    games. They needed a strong brand with a visual background
                    to be able to distinguish themselves in this huge
                    entertainment industry. After a few talks, our clients
                    became the main heroes of the visual part of the new brand.
                    We love a situation where design does itself. Work / fun is
                    easy and fun!!!
                  </>
                }
                title="The Three Legends"
                maxTitleWidth="13.3rem"
              />
            }
          />
          <Route
            path="/jumponce"
            element={
              <CasePage
                coverUrl="covers/cover_1.png"
                isWhite={true}
                projects={[Projects.ttl, Projects.gorrion, Projects.kontakt]}
                skills={
                  <Skills>
                    Logo Design
                    <br />
                    Brand Positioning
                    <br />
                    Digital Product Design
                    <br />
                    UX Design
                    <br />
                    UI Design
                    <br />
                    Frontend & Backend Development
                    <br />
                    App Development
                    <br />
                    Startup App Development
                  </Skills>
                }
                tiles={[
                  {
                    size: 1,
                    url: "tiles/jumponce/Jumponce_1-min.png",
                  },
                  {
                    size: 2,
                    url: "tiles/jumponce/Jumponce_2-min.png",
                  },
                  {
                    size: 2,
                    url: "tiles/jumponce/Jumponce_6-min.png",
                  },
                  {
                    size: 1,
                    url: "tiles/jumponce/Jumponce_3-min.png",
                  },
                  {
                    size: 2,
                    url: "tiles/jumponce/Jumponce_5-min.png",
                  },
                  {
                    size: 2,
                    url: "tiles/jumponce/Jumponce_4-min.png",
                  },
                  {
                    size: 1,
                    url: "tiles/jumponce/Jumponce_7-min.png",
                  },
                ]}
                text={
                  <>
                    A game for kids. Why not :) We love challenges and we like
                    to leave the comfort zone. After the initial enthusiasm came
                    a difficult job and a series of challenges. Could there be a
                    greater reward than the smile of a child playing your game?
                    May there be more such challenges.
                  </>
                }
                title="Jumponce"
              />
            }
          />
          <Route path="/*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default App;
