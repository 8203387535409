import AnchorLink from "components/AnchorLink";
import MenuPopup from "components/MenuPopup";
import "./menu.scss";

interface Props {
  isMain?: boolean;
  isWhite?: boolean;
}

const Menu = ({ isMain, isWhite }: Props) => {
  const fontColor = isWhite ? "font-gray3" : "font-primary";

  return (
    <div className="menu">
      <div className="menu__links">
        {isMain ? (
          <AnchorLink id="works" className={`font16 ${fontColor}`}>
            Works
          </AnchorLink>
        ) : (
          <a href="/#works" className={`font16 ${fontColor}`}>
            Works
          </a>
        )}
        {isMain ? (
          <AnchorLink id="process" className={`font16 ${fontColor}`}>
            Process
          </AnchorLink>
        ) : (
          <a href="/#process" className={`font16 ${fontColor}`}>
            Process
          </a>
        )}
        {isMain ? (
          <AnchorLink id="studio" className={`font16 ${fontColor}`}>
            Studio
          </AnchorLink>
        ) : (
          <a href="/#studio" className={`font16 ${fontColor}`}>
            Studio
          </a>
        )}
      </div>
      <MenuPopup isWhite={isWhite} />
    </div>
  );
};

export default Menu;
