import { useEffect, useState } from "react";
import getClassNames from "utils/getClassNames";
import Logo from "../Logo";
import Menu from "../Menu";
import "./header.scss";

interface Props {
  isMain?: boolean;
  isWhite?: boolean;
}

const Header = ({ isMain, isWhite }: Props) => {
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    const scrollHandle = () => {
      const scrollY = window.scrollY;
      if (scrollY > 50) {
        setIsHidden(true);
      } else if (scrollY === 0 && isHidden) {
        setIsHidden(false);
      }
    };

    window.addEventListener("scroll", scrollHandle);
    return () => {
      window.removeEventListener("scroll", scrollHandle);
    };
  }, [isHidden]);

  return (
    <div
      className={getClassNames("header", {
        white: isWhite,
        hidden: isHidden,
      })}
    >
      <Logo isWhite={isWhite} />
      <Menu isMain={isMain} isWhite={isWhite} />
    </div>
  );
};

export default Header;
