import { useNavigate } from "react-router-dom";
import "./logo.scss";

interface Props {
  isWhite?: boolean;
}

const Logo = ({ isWhite }: Props) => {
  const navigate = useNavigate();

  return (
    <div className="logo">
      <img
        className="logo__image"
        alt="logo"
        src={isWhite ? "logo-white.svg" : "logo.svg"}
        onClick={() => navigate("/")}
      />
    </div>
  );
};

export default Logo;
