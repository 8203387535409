import { ReactNode } from "react";
import "./Skills.scss";

interface Props {
  children: ReactNode;
}

const Skills = ({ children }: Props) => (
  <div className="skills font16 font-secondary">{children}</div>
);

export default Skills;
