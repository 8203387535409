import { Footer, Header, Link, Video } from "components";
import Grid from "components/Grid";
import ICaseTile from "models/ICaseTile";
import IProject from "models/IProject";
import { ReactNode } from "react";
import getClassNames from "utils/getClassNames";
import "./CasePage.scss";

interface Props {
  coverUrl: string;
  isWhite: boolean;
  isVideo?: boolean;
  maxTitleWidth?: string | number;
  projects: IProject[];
  skills: ReactNode;
  subtitle?: string;
  text: ReactNode;
  tiles: ICaseTile[];
  title: string;
}

const CasePage = ({
  coverUrl,
  isWhite,
  isVideo,
  maxTitleWidth = "auto",
  projects,
  skills,
  subtitle,
  text,
  tiles,
  title,
}: Props) => {
  const openPage = (link?: string) => {
    if (link) window.open(link, "_blank");
  };

  return (
    <div className="case-page">
      <div className="layout z-index-top">
        <Header isWhite={isWhite} />
      </div>
      {isVideo ? (
        <Video className="case-page__cover" url={coverUrl} />
      ) : (
        <img className="case-page__cover" src={coverUrl} alt="cover" />
      )}

      <div className="layout">
        <div className="case-page__content">
          <div className="case-page__title font32">
            <div style={{ maxWidth: maxTitleWidth }}>{title}</div>
            {subtitle && (
              <span className="case-page__subtitle font24">{subtitle}</span>
            )}
          </div>
          <div className="case-page__text-wrapper">
            <div className="case-page__text font26">{text}</div>
            {skills}
          </div>
        </div>
      </div>
      <div className="case-page__tiles">
        {tiles.map((tile, index) => (
          <div
            key={index}
            className={getClassNames("case-page__tile", {
              size: `size${tile.size}`,
            })}
          >
            {tile.children ? (
              tile.children.map((child, childIndex) => (
                <div
                  className={getClassNames("case-page__tile-content", {
                    active: !!child.link,
                  })}
                  onClick={() => openPage(child.link)}
                >
                  {child.isVideo ? (
                    <Video key={childIndex} url="" />
                  ) : (
                    <img
                      key={childIndex}
                      className="case-page__tile-image"
                      src={child.url}
                      alt="Case tile"
                    />
                  )}
                </div>
              ))
            ) : (
              <div
                className={getClassNames("case-page__tile-content", {
                  active: !!tile.link,
                })}
                onClick={() => openPage(tile.link)}
              >
                {tile.isVideo ? (
                  <Video url={tile.url} />
                ) : (
                  <img
                    className="case-page__tile-image"
                    src={tile.url}
                    alt="Case tile"
                  />
                )}
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="layout">
        <Link className="case-page__other-title" text="Other works" size={34} />
        <Grid
          tiles={projects.map((project, index) => ({
            size: index === 0 ? 4 : 3,
            tile: project,
          }))}
        />
        <Footer />
      </div>
    </div>
  );
};

export default CasePage;
