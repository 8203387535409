import Contact from "components/Contact";
import Letswork from "components/Letswork";
import "./footer.scss";

const Footer = () => (
  <div className="footer font-gray1">
    <Letswork className="footer__letswork" />
    <div className="footer__info-wrapper">
      <Contact />
    </div>
    <div className="footer__copy font14">©2022 neattypo</div>
  </div>
);

export default Footer;
