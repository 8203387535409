import Tile from "components/Tile";
import ITile from "models/ITile";
import "./Grid.scss";

interface Props {
  tiles: ITile[];
}

const Grid = ({ tiles }: Props) => (
  <div className="grid">
    {tiles.map((tile, id) => (
      <Tile key={id} {...tile} />
    ))}
  </div>
);

export default Grid;
