import Link from "components/Link";
import ITile from "models/ITile";
import { MouseEvent, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import getClassNames from "utils/getClassNames";
import "./tile.scss";

const Tile = ({ size, tile, title }: ITile) => {
  const imageRef = useRef<HTMLImageElement>(null);
  const navigate = useNavigate();

  const [isActive, setIsActive] = useState(false);
  const [viewPosition, setViewPosition] = useState({
    x: 0,
    y: 0,
  });

  const handleMouseEnter = () => {
    setIsActive(true);
  };

  const handleMouseLeave = () => {
    setIsActive(false);
  };

  const handleMouseMove = (e: MouseEvent) => {
    const bounds = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - bounds.left;
    const y = e.clientY - bounds.top;
    setViewPosition({ x, y });
  };

  const handleClick = () => {
    if (tile && tile.url) {
      navigate(tile.url);
      window.scrollTo(0, 0);
    }
  };

  return (
    <div className={`tile size${size}`}>
      {tile ? (
        <>
          <div
            className="tile__image-wrapper"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onMouseMove={handleMouseMove}
          >
            <img
              ref={imageRef}
              className="tile__image"
              alt={tile.title}
              src={tile.image}
              onClick={handleClick}
            />
            <div
              className={getClassNames(
                "tile__mouse",
                { active: isActive },
                "font18 font-gray2"
              )}
              style={{ top: viewPosition.y, left: viewPosition.x }}
            >
              View
            </div>
          </div>
          <div className="font20">{tile.title}</div>
          <div className="font18 font-gray1">{tile.text}</div>
        </>
      ) : title ? (
        <Link className="tile__link" size={34} text={title} />
      ) : (
        "To be, or not to be?"
      )}
    </div>
  );
};

export default Tile;
