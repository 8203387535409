const Projects = {
  kontakt: {
    image: "covers/cover_web_4.png",
    text: "Industry leader in indoor location services and Bluetooth Low Energy beacons.",
    title: "kontakt.io",
    url: "/kontakt.io",
  },
  windykacja: {
    image: "covers/cover_web_6.png",
    text: "How to give a human expression to a brand in a difficult industry.",
    title: "Windykacja plus",
    url: "/windykacja",
  },
  gorrion: {
    image: "covers/cover_web_5.png",
    text: "How to make a software house company stand out?",
    title: "Gorrion software house",
    url: "/gorrion.io",
  },
  vybbe: {
    image: "covers/cover_web_2.png",
    text: "Assessing yourself at work doesn't have to be a stressful experience.",
    title: "vybbe",
    url: "/vybbe",
  },
  adapta: {
    image: "covers/cover_web_1.png",
    text: "Sometimes it is enough to just talk to help other people.",
    title: "Adapta",
    url: "/adapta",
  },
  vizitr: {
    image: "covers/cover_web_7.png",
    text: "Pull out your phone, take a picture and get rid of the problem.",
    title: "Vizitr",
    url: "/vizitr",
  },
  ttl: {
    image: "covers/cover_web_3.png",
    text: "How spontaneous decisions can lead to good businesses.",
    title: "The Three Legends",
    url: "/ttl",
  },
  jumponce: {
    image: "covers/cover_web_8.png",
    text: "Jump, jump, jump. How we made our first game.",
    title: "Jumponce",
    url: "/jumponce",
  },
};

export default Projects;
