import { MouseEvent, ReactNode } from "react";
import getClassNames from "utils/getClassNames";
import { scrollToElementById } from "utils/scrollToElement";
import "./AnchorLink.scss";

interface Props {
  children: ReactNode;
  className?: string;
  id: string;
}

const AnchorLink = ({ children, className, id }: Props) => {
  const handleClick = (e: MouseEvent) => {
    e.preventDefault();
    scrollToElementById(id);
  };

  return (
    <a
      className={getClassNames("anchor-link", {}, className)}
      href={`#${id}`}
      onClick={handleClick}
    >
      {children}
    </a>
  );
};

export default AnchorLink;
