import "./studio.scss";

const Studio = () => (
  <div className="studio">
    <div className="studio__row">
      <div className="studio__text font22 font-secondary">
        We work together and toward. To bring ideas to life. With intention and
        meaning. For today, tomorrow, and beyond.
      </div>
      <div className="studio__image">
        <img src="/studio-image.png" alt="studio" />
        <div className="studio__image-text font22 font-gray1">
          Our team of strategists, writers, and designers define, create, and
          activate systems that change behaviors and power tomorrow’s brands.
        </div>
      </div>
    </div>
    <div className="studio__row">
      <div className="studio__title font26 font-secondary">Trusted us</div>
      <div className="studio__logos">
        <img
          className="studio__logo"
          src="logos/logo-sanofi.svg"
          alt="sanofi"
        />
        <img className="studio__logo" src="logos/logo-bmw.svg" alt="BMW" />
        <img
          className="studio__logo"
          src="logos/logo-volkswagen.svg"
          alt="Volkswagen"
        />
        <img
          className="studio__logo"
          src="logos/logo-orange.svg"
          alt="Orange"
        />
        <img
          className="studio__logo"
          src="logos/logo-zywieczdroj.svg"
          alt="Żywiec Zdrój"
        />
        <img className="studio__logo" src="logos/logo-amica.svg" alt="Amica" />
        <img
          className="studio__logo"
          src="logos/logo-vattenfall.svg"
          alt="Vattenfall"
        />
        <img
          className="studio__logo"
          src="logos/logo-bankpolski.svg"
          alt="PKO BP"
        />
        <img
          className="studio__logo"
          src="logos/logo-beefeater.svg"
          alt="Beefeater"
        />
        <img
          className="studio__logo"
          src="logos/logo-mediamarkt.svg"
          alt="MediaMarkt"
        />
        <img
          className="studio__logo"
          src="logos/logo-bankpekao.svg"
          alt="Pekao"
        />
        <img className="studio__logo" src="logos/logo-ing.svg" alt="ING" />
        <img
          className="studio__logo"
          src="logos/logo-nutricia.svg"
          alt="Nutricia"
        />
      </div>
    </div>
  </div>
);

export default Studio;
